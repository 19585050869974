<template>
  <CHeader>
    <CToggler
      v-if="mapContext"
      in-header
      class="ml-3 d-lg-none"
      @click="$store.dispatch('ui/toggleSidebarMobile')"
    />
    <CToggler
      v-if="mapContext"
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.dispatch('ui/toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto" to="/">
      <img
        v-if="!mapContext"
        class="brand-logo c-d-dark-none"
        :src="require('@/assets/brand/brickworks-skyfall-horizontal-light.svg')"
        alt=""
      />
      <img
        v-if="!mapContext"
        class="brand-logo c-d-default-none"
        :src="require('@/assets/brand/brickworks-skyfall-horizontal-dark.svg')"
        alt=""
      />
    </CHeaderBrand>
    <CHeaderNav class="header-nav">
      <!-- CHeaderNavItem v-if="!mapContext" class="pr-3 c-d-legacy-none header-nav-item">
        <img class="brand-logo c-d-dark-none" :src="require('@/assets/brand/brickworks-skyfall-horizontal-light.svg')" alt="" />
        <img class="brand-logo c-d-default-none" :src="require('@/assets/brand/brickworks-skyfall-horizontal-dark.svg')" alt="" />
      </CHeaderNavItem -->
      <CHeaderNavItem
        v-if="mapContext"
        class="pr-3 c-d-legacy-none header-nav-item"
      >
        <presenter-logo />
      </CHeaderNavItem>
      <!-- CHeaderNavItem class="pl-2 c-d-legacy-none header-nav-item">
        <button
          class="c-header-nav-btn"
          @click="() => $store.dispatch('ui/toggleDarkMode')"
        >
          <img
            class="light-mode-icon c-d-dark-none"
            src="@/assets/img/sidebar/cil-sun-light.svg"
          />
          <img
            class="dark-mode-icon c-d-default-none"
            src="@/assets/img/sidebar/cil-moon-dark.svg"
          />
        </button>
      </CHeaderNavItem -->
      <CHeaderNavItem class="pl-2 c-d-legacy-none header-nav-item">
        <settings-dropdown :darkLight="false" />
      </CHeaderNavItem>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import SettingsDropdown from "@/components/ui/SettingsDropdown";
import PresenterLogo from "@/components/ui/PresenterLogo";
export default {
  name: "TheHeader",
  components: {
    SettingsDropdown,
    PresenterLogo,
  },
  computed: {
    imgSrc() {},
  },
  props: {
    mapContext: {
      type: Boolean,
      default: "true",
    },
  },
};
</script>
<style lang="scss" scoped>
.brand-logo {
  max-width: 200px;
  text-align: left;
  height: 56px;
}

.header-nav-item {
  float: right;
}

.header-nav {
  float: right;
  justify-content: flex-end;
}
img.light-mode-icon,
img.dark-mode-icon {
  margin: 5px;
  width: 20px;
}

.c-header.c-header-light.c-header-fixed {
  background-color: #434e65;
  box-shadow: none;
}

button.c-header-toggler > span {
  background-image: url("~@/assets/img/app/sandwich.svg");
  &:hover {
    background-image: url("~@/assets/img/app/sandwich.svg");
  }
}
</style>
