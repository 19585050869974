<template>
  <div class="settings-dropdown">
    <CDropdown
      :show.sync="show"
      color="primary"
      toggler-text="Dropdown Button"
      class=""
    >
      <template #toggler>
        <button v-if="darkLight" class="c-header-nav-btn">
          <img
            class="settings-icon c-d-dark-none"
            src="@/assets/img/sidebar/cil-settings-light.svg"
          />
          <img
            class="settings-icon c-d-default-none"
            src="@/assets/img/sidebar/cil-settings-dark.svg"
          />
        </button>
        <button v-if="!darkLight" class="c-header-nav-btn">
          <img
            class="settings-icon"
            src="@/assets/img/sidebar/cil-settings-dark.svg"
          />
        </button>
      </template>
      <settings-dropdown-item
        v-for="(item, index) in settings"
        :key="index"
        :label="item.label ? item.label : ''"
        :icon="item.icon"
        :user-label="item.userLabel ? item.userLabel : false"
        :route="item.route ? item.route : null"
        :disabled="item.disabled ? true : false"
        :action="item.action ? item.action : null"
        @click.native="hide"
      >
        <notification-settings v-if="item.notificationSettings" />
      </settings-dropdown-item>
    </CDropdown>
  </div>
</template>
<script>
import SettingsDropdownItem from "@/components/ui/SettingsDropdownItem";
import NotificationSettings from "@/components/ui/NotificationSettings";
export default {
  name: "SettingsDropdown",
  components: {
    SettingsDropdownItem,
    NotificationSettings,
  },
  props: {
    darkLight: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      settings: [
        {
          label: `version ${process.env.VUE_APP_VERSION}`,
          icon: "cil-settings",
          disabled: true,
        },
        {
          icon: "cil-user",
          userLabel: true,
          route: "user-details",
          disabled: true,
        },
        {
          label: "Settings",
          icon: "cil-settings",
          route:
            this.$route.name === "maps"
              ? "selection-user-details"
              : "map-user-details",
        },
        {
          label: "Map Selection",
          route: "maps",
          icon: "cil-home",
        },
        {
          label: "Logout",
          icon: "cil-account-logout",
          action: "logout",
        },
      ],
      show: false,
    };
  },
  watch: {
    show(val) {
      //console.log("Show: ", val)
    },
  },
  methods: {
    hide() {
      console.log("running");
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
img.settings-icon {
  margin: 5px;
  width: 20px;
}
</style>
