<template>
  <div>
    <CRow>
      <div v-if="errors" class="form-group alert errorMsg">
        <ul class="errors">
          <li v-for="error in validationErrors" :key="error" class="errorMsg">
            {{ error }}
          </li>
        </ul>
      </div>
    </CRow>
    <div class="edit-asset-form">
      <CRow class="fields-button pt-3">
        <CCol class="description-notes px-0" sm="11">
          <CRow>
            <CCol sm="12" class="pl-1">
              <CInput
                :value.sync="asset.properties.skyfall.name"
                addLabelClasses="label"
                placeholder="Enter asset name"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12" class="pl-1">
              <CTextarea
                :value.sync="asset.properties.skyfall.content"
                addLabelClasses="label"
                placeholder="Enter details here"
                class="bwx-textarea"
                rows="5"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol class="buttons mt-0 px-0" sm="1">
          <button class="close" @click.stop="$emit('close')">
            <img
              class="arrow-icon c-d-dark-none"
              src="@/assets/img/sidebar/chevron-light-down.svg"
            />
            <img
              class="arrow-icon c-d-default-none"
              src="@/assets/img/sidebar/chevron-dark-down.svg"
            />
          </button>
        </CCol>
      </CRow>
      <CRow>
        <pill-single-select
          tag="Asset Type"
          :data.sync="asset.properties.skyfall.type"
          :src="ASSET_TYPES"
        ></pill-single-select>
      </CRow>
      <CRow>
        <pill-multi-select
          v-if="allTasks"
          tag="Related Notes"
          :data.sync="asset.idsAssociatedTask"
          :src="allTasks"
          type="task"
        ></pill-multi-select>
        <p v-if="!allTasks">No available notes.</p>
      </CRow>
      <CRow class="save-row ml-1">
        <div>&nbsp;</div>
        <div
          class="btn btn-outline-success save-button"
          @click.stop="handleSave"
        >
          Save
        </div>
      </CRow>
    </div>
    <div class="loading-wrapper">
      <loading
        :isFullPage="false"
        v-if="isLoading"
        :active="isLoading"
      ></loading>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { ASSET_TYPES } from "@/data/types.js";
import editFormMixin from "@/mixins/editFormMixin";
import PillMultiSelect from "@/components/common/PillMultiSelect";
import PillSingleSelect from "@/components/common/PillSingleSelect";
import Loading from "vue-loading-overlay";

export default {
  name: "EditAsset",
  components: {
    PillMultiSelect,
    PillSingleSelect,
    Loading,
  },
  mixins: [editFormMixin],
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      ASSET_TYPES,
      validationErrors: [],
      errors: false,
      isLoading: false,
      allTasks: [],
      asset: {
        properties: {
          skyfall: {
            name: "",
            content: "",
            type: "Food",
          },
        },
        geometry: {
          coordinates: [0, 0],
          type: "Point",
        },
        idsAssociatedTask: [],
      },
    };
  },
  validations: {
    asset: {
      properties: {
        skyfall: {
          name: required,
        },
      },
      notes: {},
      idsAssociatedTask: [],
    },
  },
  created() {
    this.$store.dispatch("ui/setModalLoading", true);
    this.isLoading = true;

    this.allTasks = this.$store.state.map.tasks;

    if (this.id !== "new") {
      this.$store.dispatch("assets/fetchTasks", this.id).then((res) => {
        this.asset.idsAssociatedTask = res.data;
      });

      this.$store.dispatch("assets/fetchById", this.id).then((res) => {
        this.asset = res.data;
      });
    }

    this.$store.dispatch("ui/setModalLoading", false);
    this.isLoading = false;
  },
  methods: {
    setLoading(value) {
      this.isLoading = value;
    },
    setAssociation(asset) {
      this.asset.idAsset = asset.id;
    },
    handleSave() {
      this.errors = this.$v.$invalid;

      if (this.errors) {
        this.composeErrors();
        return;
      }

      this.$emit("setLoading", true);
      this.$v.$touch();
      this.save();
      // this.$refs.upDownManager.handleUpload()

      if (this.id === "new") {
        // Save new asset
        let data = {
          complete: false,
          ...this.asset,
          idMap: this.$route.query.mapId,
        };
        if (this.$route.params.latLong) {
          data.geometry = {
            coordinates: [
              this.$route.params.latLong.lng,
              this.$route.params.latLong.lat,
            ],
            type: "Point",
          };
        }
        this.$store
          .dispatch("assets/create", data)
          .then(() => {
            this.$notify({
              group: "notifications",
              title: "Asset created successfully",
              type: "success",
              position: "top left",
            });
            this.$emit("setLoading", false);
            this.$emit("close");
          })
          .catch((err) => {
            console.error(err);
            this.$emit("setLoading", false);
            this.$emit("close");
          });
      } else {
        const data = {
          data: { ...this.asset },
          id: this.asset._id,
        };
        // save existing asset
        // console.dir(data);
        this.$store
          .dispatch("assets/updateById", data)
          .then(() => {
            this.$notify({
              group: "notifications",
              title: "Note updated successfully",
              type: "success",
              position: "top right",
            });
            this.$emit("setLoading", false);
            this.$emit("close");
          })
          .catch((err) => {
            console.error(err);
            this.$emit("setLoading", false);
            this.$emit("close");
          });
      }
    },
    handleCancel() {
      this.cancel();
      this.$emit("close");
    },

    composeErrors() {
      var errorList = [];

      var nameExists = this.$v.asset.properties.skyfall.name.required;

      if (!nameExists) {
        errorList.push("Asset name is required");
      }

      this.validationErrors = errorList;
    },
  },
};
</script>
<style lang="scss" scoped>
div.edit-asset-form {
  padding: 0px 10px 0px 10px;
  color: var(--new-light-text);
}

div.c-dark-theme .edit-asset-form {
  background-color: #2a2b36;
}

.save-button {
  flex-grow: 0;
}

.form-group {
  margin-bottom: 5px;
  position: relative;
  &--error {
    border-bottom: 2px solid var(--bwx-error);
  }
}

div.btn {
  float: right;
}

.row {
  margin: 0px;
}

div.fields-button {
  display: flex;
  flex-direction: row;
  align-content: justify;
}
button.close {
  cursor: pointer;
}

img.arrow-icon {
  margin: 5px;
  width: 20px;
}

div.errorMsg {
  color: #856404;
  background-color: #fff3cd;
  width: 100%;
  border-top: 1px solid #ddd1ab;
  border-bottom: 1px solid #ddd1ab;
}

li.errorMsg {
  margin-bottom: 0rem;
}

ul.errors {
  margin-bottom: 0rem;
  padding-inline-start: 0px;
  list-style-type: none;
}
</style>
<style lang="scss">
div.save-row {
  margin-top: 10px;
  padding-left: 0px;
  padding-right: 5px;
  justify-content: space-between;
}
</style>
