<template>
  <div class="arrow-icon">
    <img
      class="arrow-icon c-d-dark-none"
      :class="{ 'd-none': isExpanded() }"
      src="@/assets/img/sidebar/chevron-light-left.svg"
      :alt="altTag()"
      @click.stop="toggleTable()"
    />
    <img
      class="arrow-icon c-d-default-none"
      :class="{ 'd-none': isExpanded() }"
      src="@/assets/img/sidebar/chevron-dark-left.svg"
      :alt="altTag()"
      @click.stop="toggleTable()"
    />
    <img
      class="arrow-icon c-d-dark-none"
      :class="{ 'd-none': !isExpanded() }"
      src="@/assets/img/sidebar/chevron-light-down.svg"
      :alt="altTag()"
      @click.stop="toggleTable()"
    />
    <img
      class="arrow-icon c-d-default-none"
      :class="{ 'd-none': !isExpanded() }"
      src="@/assets/img/sidebar/chevron-dark-down.svg"
      :alt="altTag()"
      @click.stop="toggleTable()"
    />
  </div>
</template>
<script>
export default {
  name: "ArrowIcon",
  props: {
    titleSingle: String,
    titleMultiple: String,
    categorySingle: String,
    categoryMultiple: String,
  },
  computed: {},
  watch: {},
  methods: {
    altTag() {
      return `Toggle ${this.titleMultiple}`;
    },
    toggleTable() {
      this.$emit("toggle-table");
    },
    isExpanded() {
      let name = this.$route.name;

      if (name == this.categoryMultiple) return true;

      if (name == `new-${this.categorySingle}`) return true;

      return false;
    },
  },
};
</script>
<style scoped>
div.arrow-icon {
  flex-grow: 0;
}
img.arrow-icon {
  margin: 5px;
  width: 20px;
}
</style>
