<template>
  <div class="sidebar-modules">
    <custom-nav-dropdown
      v-if="checkActive('assets') || inBaseRoute()"
      :active="checkActive('assets')"
      titleSingle="Assets"
      titleMultiple="Assets"
      categorySingle="asset"
      categoryMultiple="assets"
      @click.native="setActive('assets')"
      @updateURL="setURLEndpoint($event, 'assets')"
    >
    </custom-nav-dropdown>
    <custom-nav-dropdown
      v-if="checkActive('tasks') || inBaseRoute()"
      :active="checkActive('tasks')"
      titleSingle="Note"
      titleMultiple="Notes"
      categorySingle="task"
      categoryMultiple="tasks"
      @click.native="setActive('tasks')"
      @updateURL="setURLEndpoint($event, 'tasks')"
    >
    </custom-nav-dropdown>
    <custom-nav-dropdown
      v-if="checkActive('incidents') || inBaseRoute()"
      :active="checkActive('incidents')"
      titleSingle="Incident"
      titleMultiple="Incidents"
      categorySingle="incident"
      categoryMultiple="incidents"
      @click.native="setActive('incidents')"
      @updateURL="setURLEndpoint($event, 'incidents')"
    >
    </custom-nav-dropdown>
    <custom-nav-dropdown
      v-if="checkActive('emergencies') || inBaseRoute()"
      :active="checkActive('emergencies')"
      titleSingle="Emergency"
      titleMultiple="Emergencies"
      categorySingle="emergency"
      categoryMultiple="emergencies"
      @click.native="setActive('emergencies')"
      @updateURL="setURLEndpoint($event, 'emergencies')"
    >
    </custom-nav-dropdown>
  </div>
</template>

<script>
import CustomNavDropdown from "./CustomNavDropdown";
import NavMapLayers from "./NavMapLayers";
import { entityList } from "@/utils/entities";
export default {
  name: "SidebarModuleNav",
  components: {
    CustomNavDropdown,
    NavMapLayers,
  },
  data() {
    return {
      activeDropdown: null,
    };
  },
  created() {
    var pathName = this.$route.name;
    // endpoints that do not open the sidebar
    if (pathName && pathName !== "map" && pathName !== "map-user-details") {
      this.setActive(this.mapRouteNameToEntityName(pathName));
      this.$store.dispatch("ui/setSidebarShow", true);
    } else if (pathName == "map-user-details") {
      // routes that always want the sidebar closed
      this.$store.dispatch("ui/setSidebarShow", false);
    }
  },
  watch: {
    "$route.name": function (pathName) {
      if (pathName && pathName !== "map" && pathName !== "map-user-details") {
        this.setActive(this.mapRouteNameToEntityName(pathName));
        this.$store.dispatch("ui/setSidebarShow", true);
      } else if (pathName == "map-user-details") {
        // routes that always want the sidebar closed
        this.$store.dispatch("ui/setSidebarShow", false);
      }
    },
  },
  computed: {
    lineSrc() {
      return this.$store.state.map.tools.line
        ? require("@/assets/img/sidebar/line-yellow.png")
        : require("@/assets/img/sidebar/line.png");
    },
    polygonSrc() {
      return this.$store.state.map.tools.polygon
        ? require("@/assets/img/sidebar/polygon-yellow.png")
        : require("@/assets/img/sidebar/polygon.png");
    },
    isAdmin() {
      return this.$store.state.user.isAdmin;
    },
  },
  mounted() {
    this.$store.dispatch("map/fetchAllData");
  },
  methods: {
    inBaseRoute() {
      return this.$route.name === "map";
    },
    checkActive(name) {
      return this.mapRouteNameToEntityName(this.$route.name) === name;
    },
    setURLEndpoint(isOpen, endpoint) {
      // sets the URL endpoint to the current active if isOpen
      // otherwise resets it to `/`
      if (isOpen) {
        this.$router.push({ name: endpoint, query: { ...this.$route.query } });
      } else {
        this.$router.push({ path: "/", query: { ...this.$route.query } });
      }
    },
    setActive(name) {
      // set the active dropdown
      if (this.$route.name !== "map") this.activeDropdown = name;
    },
    navigateNewEntity(name) {
      this.$router.push({
        path: `/${name}/new`,
        query: { ...this.$route.query },
      });
      this.setActive(this.mapRouteNameToEntityName(name));
    },
    setEntityParam(key, value) {
      // clears the query params of all entity-parameters
      // ensures only one entity Param is present
      var queryParams = this.$route.query;
      for (var entity of entityList) {
        delete queryParams[entity];
      }
      this.$router.push({ path: "/", query: { ...queryParams, [key]: value } });
    },
    mapRouteNameToEntityName(routeName) {
      if (
        routeName === "task-detail" ||
        routeName === "tasks" ||
        routeName === "new-task"
      ) {
        return "tasks";
      } else if (
        routeName === "asset-detail" ||
        routeName === "assets" ||
        routeName === "new-asset"
      ) {
        return "assets";
      } else if (
        routeName === "incident-detail" ||
        routeName === "incidents" ||
        routeName === "new-incident"
      ) {
        return "incidents";
      } else if (
        routeName === "emergency-detail" ||
        routeName === "emergencies" ||
        routeName === "new-emergency"
      ) {
        return "emergencies";
      }
    },
    scrollToTop() {
      this.$el.parentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
img.img-icon {
  width: 20px;
  box-shadow: none;
  border: none;
}
</style>
