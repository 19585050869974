module.exports = {
    entityList: ["incident", "task", "asset"],
    setEntityParam(key, value) {
        // clears the query params of all entity-parameters
        // ensures only one entity Param is present
        var queryParams = this.$route.query
        for (var entity of entityList) {
          delete queryParams[entity]
        }
        this.$router.push({path: '/', query: {...queryParams, [key]: value}})
      }
}