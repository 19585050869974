<template>
  <div class="container pl-0 pr-1">
    <span class="ml-1">{{ tag }}</span>
    <button class="btn btn-ghost plus-button p-0" @click.stop="toggleInput">
      <img
        class="create-button c-d-dark-none"
        src="@/assets/img/sidebar/plus-light.svg"
        alt="Add"
      />
      <img
        class="create-button c-d-default-none"
        src="@/assets/img/sidebar/plus-dark.svg"
        alt="Add"
      />
    </button>
    <div class="content">
      <CCol sm="12" class="pl-0">
        <CBadge
          class="pill multi"
          shape="pill"
          v-for="(obj, index) in data"
          :key="obj._id || obj"
        >
          <span class="identifier pl-1 pr-1">
            {{ objectToString(obj) }}
            <button
              class="btn btn-ghost p-0 remove-button"
              alt="Remove"
              @click.stop="removeEntry(index)"
            >
              <img
                class="remove-button c-d-dark-none"
                src="@/assets/img/sidebar/x-light.png"
                alt="Add"
              />
              <img
                class="remove-button c-d-default-none"
                src="@/assets/img/sidebar/x-dark.png"
                alt="Add"
              />
            </button>
          </span>
        </CBadge>
      </CCol>
    </div>
    <div class="wrapper">
      <div class="input-modal" v-if="showInput" @click.stop="focusInput">
        <div class="container">
          <form
            enctype="multipart/form-data"
            novalidate
            v-if="isInitial || isSaving"
          >
            <div class="dropbox">
              <input
                type="file"
                multiple
                :name="uploadFieldName"
                :disabled="isSaving"
                @change="
                  filesChange($event.target.name, $event.target.files);
                  fileCount = $event.target.files.length;
                "
                accept="image/*"
                class="input-file"
              />
              <p v-if="isInitial">
                Drag your file(s) here to begin<br />
                or click to browse
              </p>
              <p v-if="isSaving">Uploading {{ fileCount }} files...</p>
            </div>
          </form>

          <div class="backtest1">
            <p class="overlay">Hello Boozer</p>
          </div>

          <div class="backtest2">
            <p class="overlay">Hello Boozer</p>
          </div>

          <div v-if="isSuccess">
            <h2>Uploaded {{ uploadedFiles.length }} file(s) successfully.</h2>
            <p>
              <a href="javascript:void(0)" @click="reset()">Upload again</a>
            </p>
            <ul class="list-unstyled">
              <li v-for="item in uploadedFiles" :key="item">
                <img
                  :src="item.url"
                  class="img-responsive img-thumbnail"
                  :alt="item.originalName"
                />
              </li>
            </ul>
          </div>

          <div v-if="isFailed">
            <h2>Uploaded failed.</h2>
            <p>
              <a href="javascript:void(0)" @click="reset()">Try again</a>
            </p>
            <pre>{{ uploadError }}</pre>
          </div>
        </div>
        <button
          class="done btn-outline-success mt-1 mr-1"
          @click.stop="toggleInput"
        >
          Done
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  name: "PillAttachments",
  components: {},
  props: {
    // data to be populated into pills (currently selected items)
    data: {
      required: true,
      default() {
        return [];
      },
      validator(value) {
        var valid = value instanceof Array || value == null;
        if (!valid)
          console.error(
            `Validation check failed for data in PillAttachments \nExpected: array || null \nReceived `,
            typeof value
          );
        return valid;
      },
    },
    tag: String, // description to be put above component
    src: {
      // the data or data source for MultiSelect
      required: true,
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      required: false,
      validator(value) {
        return (
          value === "asset" ||
          value === "task" ||
          value === "incident" ||
          value === "string" ||
          value === "user"
        );
      },
    },
  },
  data() {
    return {
      enteredValue: null,
      showInput: false,
      options: [],
      selectClasses: [],
      strSrc: [],
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "photos",
    };
  },
  updated: function () {
    // todo not working
    this.$nextTick(function () {
      this.focusInput();
    });
  },
  created() {
    this.strSrc = this.src.map((e) => {
      return this.objectToString(e);
    });

    this.options = this.mapToOptions(this.strSrc);
  },
  computed: {
    selected() {
      var arr = [];
      for (var i = 0; i < this.src.length; i++) {
        // selected's index in options
        var entry = this.src[i];
        var fromSelected = this.data.filter((e) => {
          return e._id === entry._id;
        })[0];
        if (fromSelected) {
          arr.push(i.toString());
        }
      }
      return arr;
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  watch: {
    data(value) {
      if (value && !this.selected) {
        var arr = [];
        for (var i = 0; i < this.src.length; i++) {
          // selected's index in options
          var entry = this.src[i];
          var fromSelected = this.data.filter((e) => {
            return e._id === entry._id;
          })[0];
          if (fromSelected) {
            arr.push(i.toString());
          }
        }
        return arr;
      }
    },
    "$refs.multiSelect.classes": function (value) {
      console.log("classList: ", value);
    },
  },
  methods: {
    mapToOptions(arr) {
      // maps strings to objects compatible with CMultiSelect
      return arr.map((e, i) => {
        return {
          value: i.toString(),
          text: e,
        };
      });
    },
    removeEntry(index) {
      var newList = this.data.slice();
      newList.splice(index, 1);
      this.$emit("update:data", newList);
    },
    createEntry(event) {
      // enter enteredValue into the list
      var selectedElements = event.map((i) => {
        return this.src[i];
      });
      this.$emit("update:data", selectedElements);
    },
    toggleInput() {
      this.showInput = !this.showInput;
    },
    focusInput() {
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    },
    objectToString(obj) {
      if (this.type === "user") {
        return obj.name;
      } else if (this.type === "asset") {
        if (obj.properties && (obj.properties.name || obj.properties.skyfall))
          return obj.properties.name || obj.properties.skyfall.name;
        else return "undefined";
      } else if (this.type === "task" || this.type === "incident") {
        return obj.title;
      } else {
        return obj;
      }
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    wait(ms) {
      return (x) => {
        return new Promise((resolve) => setTimeout(() => resolve(x), ms));
      };
    },
    upload(formData) {
      const url = `${BASE_URL}/photos/upload`;
      return (
        axios
          .post(url, formData)
          // get data
          .then((x) => x.data)
          // add url field
          .then((x) =>
            x.map((img) =>
              Object.assign({}, img, { url: `${BASE_URL}/images/${img.id}` })
            )
          )
      );
    },
    save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;

      upload(formData)
        .then(wait(1500)) // DEV ONLY: wait for 1.5s
        .then((x) => {
          this.uploadedFiles = [].concat(x);
          this.currentStatus = STATUS_SUCCESS;
        })
        .catch((err) => {
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData();

      if (!fileList.length) return;

      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });

      // save it
      this.save(formData);
    },
  },
  mounted() {
    this.reset();
  },
};
</script>

<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.backtest1 {
  background-color: #434e65;
  background-image: url("~@/assets/brand/brickworks-contours.svg");
  border: 1px solid #434e65;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.backtest2 {
  background-color: #434e65;
  background-image: url("~@/assets/img/sidebar/chevron-dark-down.svg");
  border: 1px solid #434e65;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

p.overlay {
  background-color: none;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
<style>
div.container {
  width: 100%;
}
div.content {
  display: flex;
  width: 100%;
  margin-bottom: 6px;
}

button.plus-button {
  float: right;
}

.input-modal {
  background-color: var(--new-light-bg);
  position: absolute;
  z-index: 69;
  top: 0px;
  left: 0px;
  width: 100%;
  border: 1px solid var(--new-light-border);
  border-radius: 5px;
  padding: 10px;
  margin-top: 0px;
}

input {
  margin-bottom: 10px;
}

.wrapper {
  position: relative;
}

.pill.multi {
  border: 1px solid var(--new-light-border);
  margin: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  background: white;
  width: fit-content;
  vertical-align: middle;
}

.create-button {
  width: 20px;
}

button.remove-button {
  border: none;
  height: 10px;
  width: 10px;
  line-height: 10px;
}

img.remove-button {
  margin-top: -2px;
  width: 10px;
  height: 10px;
}

input:focus,
button.btn:focus,
button.done:focus,
button:active {
  outline: none;
}

.done {
  background-color: var(--new-light-bg);
  color: var(--matte-green);
  border-radius: 0.25rem;
}

.c-multi-select-options {
  height: 200px;
  overflow-y: scroll;
}

div.plus-button {
  display: flex;
  align-content: center;
}

span.identifier {
  vertical-align: middle;
  font-size: 14px;
}

span {
  font-weight: 400;
}
</style>
